import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
const initialState = {
    token: '',
    loading: false,
    error: null,
};

export const login = createAsyncThunk(
    'auth/login',
    async ({ username, password, loginType }) => {
        console.log("cooddd "+loginType)
        try {
            let response;

            switch (loginType) {
                case 'email':
                response = await axios.post('https://api.sociverse.in/auth/login/email', {
                    email: username,
                    password: password,
                    loginType: "PASSWORD",
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                break;

                case 'phoneNumber':
                response = await axios.post('https://api.sociverse.in/auth/login/mobileNumber', {
                    "mobileNumber": username,
                    "countryCode" : "+91",
                    loginType: "PASSWORD",
                    password: password
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                break;

                default:
                throw new Error('Invalid input type: must be "email" or "number"');
            }
            const accessToken = response.data.result.accessToken;
            localStorage.setItem('accessToken', accessToken);
            return response.data.result.accessToken;
        } catch (error) {
            throw error.response.data.error.message;
        }
    }
);

const authSlice = createSlice({
    name: 'auth',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.loading = false;
                state.token = action.payload;
                state.error = null;
            })
            .addCase(login.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                state.token =null;
            });
    },
});

export default authSlice.reducer;
