// src/features/event/eventSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    postEvent: null,
    status: 'idle',
    error: null,
};

// Create async thunk for posting event data
export const postEvent = createAsyncThunk(
    'event/postEvent',
    async ({eventData,accessToken}, { rejectWithValue }) => {
        try {
            const response = await axios.post('https://api.sociverse.in/events/create', eventData, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data || error.message);
        }
    }
);

const postEventSlice = createSlice({
    name: 'postEvent',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(postEvent.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(postEvent.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.postEvent = action.payload;
            })
            .addCase(postEvent.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export default postEventSlice.reducer;
