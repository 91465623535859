import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk for fetching joined events
export const fetchJoinedEvents = createAsyncThunk(
    'joinedEvents/fetchJoinedEvents',
    async ({ latitude ,longitude,accessToken ,id}, { rejectWithValue }) => {

        try {
            if(id){
                const response = await axios.get(
                    `https://api.sociverse.in/userEvent?type=JOINED&userId=${id}`,
                    {
                        headers: {
                            latitude: latitude,
                            longitude: longitude,
                            Authorization: `Bearer ${accessToken}`
                        }
                    }
                );
                return response.data.result;
            }
            else
            {const response = await axios.get(
                `https://api.sociverse.in/userEvent?type=JOINED`,
                {
                    headers: {
                        latitude: latitude,
                        longitude: longitude,
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            );
                return response.data.result;
        }
            
           
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const joinedEventsSlice = createSlice({
    name: 'joinedEvents',
    initialState: {
        joinedEventsData: [],
        status: 'idle',
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchJoinedEvents.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchJoinedEvents.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.joinedEventsData = action.payload;
            })
            .addCase(fetchJoinedEvents.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
                state.joinedEventsData=[];
            });
    }
});

export default joinedEventsSlice.reducer;
