import React, { useEffect, useState } from "react";
import { useDispatch ,useSelector } from "react-redux";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { ReportData } from "../redux/slice/Report";
import { RxCross2 } from "react-icons/rx";

const DialogBox = ({ id }) => {
  const{Report}=useSelector((state)=>state.Report)
  const [isOpen, setIsOpen] = useState(false);
  const [report, setReport] = useState("");
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem("accessToken");
 useEffect(()=>{
  if (Report) { 
    alert(Report);
  }
 },[dispatch])
  const handleReport = () => {
    dispatch(ReportData({ accessToken, id, report })).then((response)=>{
      setIsOpen(false);
    });
    setReport('');
    setIsOpen(false); 
  };

  const handleCancel = () => {
    setIsOpen(false); // Close the modal without reporting
  };

  return (
    <>
      <button
        data-modal-target="crud-modal"
        data-modal-toggle="crud-modal"
        disabled={accessToken ? false : true}
        type="button"
        className="flex items-center"
        onClick={() => setIsOpen(true)}>
        <p>Report this event</p>
        <AiOutlineInfoCircle className="cursor-pointer font-bold ml-3" />
      </button>
      {isOpen && (
        <div
          id="crud-modal"
          tabIndex={-1}
          aria-hidden="true"
          className="fixed top-0 right-0 bottom-0 left-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="relative p-4 w-full max-w-md">
            {/* Modal content */}
            <div className="relative bg-white rounded-lg shadow">
              <button
                type="button"
                className="text-blue-400 bg-transparent hover:bg-blue-400 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center absolute right-0"
                onClick={() => setIsOpen(false)}>
                <RxCross2 />
                <span className="sr-only">Close modal</span>
              </button>
              <div className="flex flex-col gap-2 items-center justify-between p-4 md:p-5 border-b rounded-t">
                <p>Are you sure want to report</p>
                <div className="w-full">
                  <div className="">
                    <textarea
                      id="description"
                      rows={4}
                      value={report}
                      onChange={(e) => setReport(e.target.value)}
                      className="block p-2.5 w-full text-sm border border-blue-400 bg-slate-100 rounded-md mt-2"
                    />
                  </div>
                </div>
                <div className="flex justify-between mt-2 w-full">
                  <button
                    onClick={handleReport}
                    className="text-white flex bg-blue-400  font-medium rounded-xl text-sm p-2 text-center">
                    Report
                  </button>
                  <button
                    onClick={handleCancel}
                    className="text-white flex bg-blue-400  font-medium rounded-xl text-sm p-2 text-center">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DialogBox;
