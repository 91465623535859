import { React } from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import SignUp from "../pages/userPages/SignUp/SignUp"; 
import Loader from "../components/Loader";

const AuthControl = () => {
   const location = useLocation();
   const on = location.pathname === "/login";
   const on1 = location.pathname === "/sign-up";
   const on2 = location.pathname === "/sign-up/detail";
  


  return (
    <section className=" h-[100%] w-full flex  ">
      <div
        style={{
          backgroundImage:
            "radial-gradient(circle at 10% 20%, rgb(102, 116, 236) 0%, rgb(50, 231, 219) 90%)",
        }}
        className="left hidden  relative  md:block h-full w-[40%]">
        <div className=" absolute top-1/2 left-1/2  -translate-x-1/2 -translate-y-1/2">
          <h1
            style={{
              backgroundImage: "text-shadow: 0px 0px 5px rgba(128, 0, 0, 1)",
            }}
            className=" text-white xl:text-3xl text-2xl ">
            Discover, Connect, Experience <br />{" "}
            <strong className="font-bold">Sociverse</strong> Your Ultimate Event
            Locator!
          </h1>
        </div>

        <Link to="Login">
          <button
            className={`absolute top-56 right-0 font-bold text-sm md:text-base rounded-l-3xl w-20 md:w-auto bg-inherit p-2 md:p-3 hover:bg-white hover:text-black  ${
              on ? "bg-white text-black" : ""
            }`}>
            Log in
          </button>
        </Link>
        <Link to="signUp">
          <button
            className={`absolute top-72 right-0 font-bold text-sm md:text-base rounded-l-3xl w-20  bg-inherit p-2  hover:bg-white hover:text-black  ${
              on1 || on2 ? "bg-white text-black" : ""
            }`}>
            Sign up
          </button>
        </Link>
      </div>

      {/* left section  */}

      <Outlet />
    </section>
  );
};

export default AuthControl;
