import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const sendOTP = createAsyncThunk(
    'auth/sendOTP',
    async ({ loginType, inputValue}) => {
        try {
            let response;

            switch (loginType) {
                case 'email':
                console.log("email input "+inputValue)
                response = await axios.post('https://api.sociverse.in/auth/send-otp', {
                    "email": inputValue,
                });
                break;

                case 'phoneNumber':
                response = await axios.post('https://api.sociverse.in/auth/send-otp', {
                    "mobileNumber": inputValue,
                    "countryCode" : "91"
                });
                break;

                default:
                throw new Error('Invalid input type: must be "email" or "number"');
            }

            return response.data;
            ;
        } catch (error) {
            throw error;
        }
    }
);

const initialState = {
    loading: false,
    success: false,
    error: null,
};

const SendotpSlice = createSlice({
    name: 'otp',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(sendOTP.pending, (state) => {
                state.loading = true;
                state.success = false;
                state.error = null;
            })
            .addCase(sendOTP.fulfilled, (state) => {
                state.loading = false;
                state.success = true;
                state.error = null;
            })
            .addCase(sendOTP.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.error.message || 'Failed to send OTP';
            });
    },
});

export default SendotpSlice.reducer;
