// src/features/userEvents/userEventsSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Define the thunk
export const fetchPendingRequests = createAsyncThunk(
    'userEvents/fetchPendingRequests',
    async ({id,token}, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `https://api.sociverse.in/userEvent/pendingRequests?eventId=${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    
                }
            );
            return response.data.result;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// Create a slice
const PendingRequestSlice = createSlice({
    name: 'userEvents',
    initialState: {
        pendingRequests: [],
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPendingRequests.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPendingRequests.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.pendingRequests = action.payload;
            })
            .addCase(fetchPendingRequests.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export default PendingRequestSlice.reducer;
