// src/features/event/eventSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const rejectEvent = createAsyncThunk(
    'event/rejectEvent',
    async ({ id, token, userIds }, { rejectWithValue }) => {
        try {
            const response = await axios.put(
                `https://api.sociverse.in/events/${id}/action?action=REJECT`,
                { userIds },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            return response.data.result.message;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const RejectRequestSlice = createSlice({
    name: 'event',
    initialState: {
        Reject: "",
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(rejectEvent.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(rejectEvent.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.Reject = action.payload;
            })
            .addCase(rejectEvent.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export default RejectRequestSlice.reducer;
