// filtersSlice.js
import { createSlice } from "@reduxjs/toolkit";

const filtersDataSlice = createSlice({
    name: "filtersData",
    initialState: {
        "eventMode":"OFFLINE",
        "radius": null,
       "searchKeyword":"",
    },
    reducers: {
        updateFilters: (state, action) => {
            return { ...state, ...action.payload };
           
        },
    },
});

export const { updateFilters } = filtersDataSlice.actions;
export default filtersDataSlice.reducer;
