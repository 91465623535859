// src/interestsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';


export const fetchInterests = createAsyncThunk(
  'interests/fetchInterests',
  async () => {
    const response = await axios.get(
      'https://api.sociverse.in/resources/interests'
    );
    return response.data.result;
  }
);

const interestsSlice = createSlice({
  name: 'interests',
  initialState: {
    loading: false,
    interests: [],
    error: '',
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInterests.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchInterests.fulfilled, (state, action) => {
        state.loading = false;
        state.interests = action.payload;
        state.error = '';
      })
      .addCase(fetchInterests.rejected, (state, action) => {
        state.loading = false;
        state.interests = [];
        state.error = action.error.message;
      });
  },
});

export default interestsSlice.reducer;
