import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk to fetch professions
export const fetchTags = createAsyncThunk(
    'profession/fetchProfession',
    async () => {
        const response = await axios.get(
            'https://api.sociverse.in/resources/event-tags'
        );
        return response.data.result;
    }
);

const TagSlice = createSlice({
    name: 'tags',
    initialState: {
        loading: false,
        tags: [],
        error: '',
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTags.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchTags.fulfilled, (state, action) => {
                state.loading = false;
                state.tags = action.payload;
                state.error = '';
            })
            .addCase(fetchTags.rejected, (state, action) => {
                state.loading = false;
                state.tags = [];
                state.error = action.error.message || 'Failed to fetch professions';
            });
    },
});

export default TagSlice.reducer;
