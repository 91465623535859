import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/slice/AuthSlice";
import { useNavigate  } from "react-router-dom";
import { Link } from "react-router-dom";
import { FaEye, FaEyeSlash, FaLock } from "react-icons/fa";
import { FaRegUserCircle } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import sociverse_app_logo from "../../assets/sociverse_app_logo.png";

export const Login = () => {
  // if localstarage.access != null.
  // navgate('/profile')
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(true);
 const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
 const [emailerror,setError]=useState("")
 const [shouldSendOtp, setShouldSendOtp] = useState(false);
 const [loginType, setLoginType] = useState('');
  const dispatch = useDispatch();
  const { loading, error, token } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = () => {
     if (!loginType) {
       setError("Invalid email address");
       return;
     }
    dispatch(login({ username, password, loginType })).then((res) => {
      if (res.payload) {
        alert("Login Successfull")
        navigate("/")
      } else alert("Enter correct Details");
    });
  };
  const handleInputChange = (e) => {
    const value = e.target.value;
    setUsername(value);
    console.log("hrreee - "+value)

    // Check if the value is an email or phone number
    if (isEmail(value)) {
      setLoginType("email");
      setShouldSendOtp(true);
      // Perform email related actions here
    } else if (isPhoneNumber(value)) {
      setLoginType("phoneNumber");
      setShouldSendOtp(true);
      // Perform phone number related actions here
    } else {
      console.log('Invalid input:', value);
      setShouldSendOtp(false)
      // Handle invalid input if necessary
    }
  };

  const isEmail = (value) => {
    // Simple email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };

  const isPhoneNumber = (value) => {
    // Simple phone number validation regex
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(value);
  };

  return (
    <div className="right w-full   md:w-[60%] shadow-lg flex flex-col justify-center items-center">
      <div className="w-full p-6 md:w-[60%]">
        <div className="flex flex-col gap-12">
          <div className="logo justify-center items-center flex">
            <img
              className="h-50 w-40 bg-transparent"
              src={sociverse_app_logo}
              alt=""
            />
          </div>
          <div className="relative flex">
            <div className="absolute left-0 top-[0.4rem] p-2">
              <FaRegUserCircle />
            </div>
            <div className="w-full">
              <input
                className="appearance-none bg-inherit border-b-2 border-black p-2 pl-10 w-full"
                type="text"
                name="contact"
                placeholder="Email/Phone Number"
                required
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="relative flex">
            <div className="absolute left-0 top-[0.4rem] p-2">
              <FaLock />
            </div>
            <input
              className="appearance-none border-b-2 bg-inherit border-black p-2 pl-10 w-full"
              type={showPassword ? "text" : "password"}
              value={password}
              name="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <button type="button" onClick={handleTogglePassword}>
              {showPassword ? (
                <FaEyeSlash className="absolute top-1/2 right-3 -translate-y-1/2 " />
              ) : (
                <FaEye className="absolute top-1/2 right-3 -translate-y-1/2" />
              )}
            </button>
          </div>

          <div className="flex justify-between">
            <Link to="/reset-password">
              <button className="forget text-sm text-[#549CE6] mt-2 font-bold md:text-lg">
                <p>Forget Password</p>
              </button>
            </Link>

            <div className="login-btn">
              <button
                onClick={handleLogin}
                disabled={!shouldSendOtp}
                className="p-1 w-14 text-sm md:p-1 rounded-2xl md:w-[6rem] bg-[#549CE6] text-white md:text-lg">
                Log in
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
