import React from 'react'

const NotFound = () => {
  return (
    <div className="h-screen w-full flex justify-center items-center">
      <div >
        <p className="text-4xl font-bold">404 - Not Found</p>
        <p className='font-medium'>The page you are looking for does not exist.</p>
      </div>
    </div>
  );
}

export default NotFound