import { React, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchJoinedEvents } from "../redux/slice/JoinedEventSlice";
import { EventCard } from "./EventCard";
import Loader from "./Loader";

const JoinedEvent = ({id,title}) => {
  const [Latitude, setLatitude] = useState(null);
  const [Longitude, setLongitude] = useState(null);
  const accessToken = localStorage.getItem("accessToken");
  const { joinedEventsData, status } = useSelector(
    (state) => state.joinedEvents
  );


  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.error("Error getting geolocation:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);
  const dispatch = useDispatch();
  useEffect(() => {
    
     
      if (id) {
        dispatch(fetchJoinedEvents({ Latitude, Longitude, accessToken, id }));
      } else 
     { 
      dispatch(fetchJoinedEvents({ Latitude, Longitude, accessToken }));}
   
  }, [Latitude, Longitude, accessToken]);
  

  return (
    <>
      {status === "loading" ? (
        <div className=" flex justify-center ">
          <Loader />
        </div>
      ) : (
        <div>
          <h1 className="text-2xl ml-2 font-medium">{title}</h1>
          <div className="created-event grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-7">
            {joinedEventsData && joinedEventsData.length > 0 ? (
              joinedEventsData.map((event, index) => (
                <div key={index} className="flex justify-center p-2">
                  <div className="max-w-xs md:max-w-full">
                    <EventCard event={event} />
                  </div>
                </div>
              ))
            ) : (
              <h1>No Joined Events | Kindly open your location</h1>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default JoinedEvent;
