import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    filter: {},
    loading: false,
    error: null
}

// Async thunk for fetching filters
export const fetchFilters = createAsyncThunk(
    "resources/filters",
    async () => {
        try {
            const response = await axios.get("https://api.sociverse.in/resources/filters");
            return response.data.result;
        } catch (error) {
            throw error;
        }
    }
);

// Slice for filters
const filtersSlice = createSlice({
    name: "filters",
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchFilters.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchFilters.fulfilled, (state, action) => {
                state.loading = false;
                state.filter = action.payload;
                state.error = null; 
            })
            .addCase(fetchFilters.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

// Exporting the reducer
export default filtersSlice.reducer;
