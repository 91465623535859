import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateProfile } from "../redux/slice/UpdateProfileSlice";
import { useNavigate } from "react-router-dom";

const interestsData = [
  { id: 1, name: "Art & Culture" },
  { id: 2, name: "Entreprenuer" },
  { id: 3, name: "Leadership" },
  { id: 4, name: "Investing" },
  { id: 5, name: "Career & Business" },
  { id: 6, name: "Enviroment" },
  { id: 7, name: "Wanderlust" },
  { id: 8, name: "Newbie in City" },
  { id: 9, name: "Dancing" },
  { id: 10, name: "Stress Management & Passion" },
  { id: 11, name: "Sci-Fi/Fantasy" },
  { id: 12, name: "Yoga" },
  { id: 13, name: "LGBT Social Group" },
  { id: 14, name: "Music" },
  { id: 15, name: "Social Activities" },
  { id: 16, name: "Sports & Fitness" },
  { id: 17, name: "Travel" },
  { id: 18, name: "Technology" },
];

var flag = true;

function EditProfile() {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [formattedDate, setFormattedDate] = useState("");
  const [dob, setDOB] = useState("");
  const [selectedInterests, setSelectedInterests] = useState([]);
  const storedProfileData = localStorage.getItem("profileData");
  const parsedProfileData = storedProfileData
    ? JSON.parse(storedProfileData)
    : null;

  useEffect(() => {
    if (parsedProfileData && Object.keys(formData).length === 0) {
      setFormData(parsedProfileData);
    }

    setSelectedInterests(formData.interests);
  }, [parsedProfileData, formData]);

  useEffect(() => {
    if (formData.dob) {
      const dobDate = new Date(formData.dob);
      const year = dobDate.getFullYear();
      const month = (dobDate.getMonth() + 1).toString().padStart(2, "0"); // Ensure double digits for month
      const day = dobDate.getDate().toString().padStart(2, "0"); // Ensure double digits for day

      const formattedDOB = `${year}-${month}-${day}`;
      setDOB(formattedDOB);
    }
  }, [formData]);


  const  handleCheckboxChange = (interestId) => {
    // Check if the interest is already selected
    const StringId = interestsData.find(
      (interest) => interest.id === interestId
    );
    const StringToFind = StringId.name;
    const isSelected = selectedInterests.includes(StringToFind);
    let updatedInterests;    
    if (isSelected) {
      // If already selected, remove it from the list
      updatedInterests = selectedInterests.filter(
        (interest) => interest !== StringToFind
      );
    } else {
      updatedInterests = [...selectedInterests, StringToFind];
    }
   
    // Updating the state with the new list of selected interests
    localStorage.setItem(
      "profileData",
      JSON.stringify({ ...formData, interests: updatedInterests })
    );
    setFormData({ ...formData, interests: updatedInterests });
    setSelectedInterests(updatedInterests);
  };

  
   const handleChange = (e) => {
    if (dob){
      const date = e.target.value;
      const dateobj = new Date(date);
      setFormData({
        ...formData,
        dob: dateobj,
      });
    }
     setFormData({
       ...formData,
       [e.target.name]: e.target.value,
     });
   };
   
   const navigate=useNavigate()

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateProfile(formData));
    navigate("/profile")
  };
  const handleBack=()=>{
    navigate("/profile");
  }

  return (
    <>
   
        <div className="flex min-h-screen  items-center justify-center p-12">
          <div className="mx-auto w-full  bg-white">
            <div>
              <div className="-mx-3 flex flex-wrap">
                <div className="w-full px-3 sm:w-1/2">
                  <div className="mb-5">
                    <label
                      htmlFor="fName"
                      className="mb-3 block text-base font-medium text-[#07074D]">
                      Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={formData.name || ""}
                      onChange={handleChange}
                      id="fName"
                      placeholder="First Name"
                      className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    />
                  </div>
                </div>
                <div className="w-full px-3 sm:w-1/2">
                  <div className="mb-5">
                    <label
                      htmlFor="lName"
                      className="mb-3 block text-base font-medium text-[#07074D]">
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email || ""}
                      onChange={handleChange}
                      id="lName"
                      readOnly
                      placeholder="Email"
                      className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    />
                  </div>
                </div>
              </div>

              <div className="-mx-3 flex flex-wrap">
                <div className="w-full px-3 sm:w-1/2">
                  <div className="mb-5">
                    <label
                      htmlFor="dob"
                      className="mb-3 block text-base font-medium text-[#07074D]">
                      Date of Birth
                    </label>
                    <input
                      type="date"
                      name="dob"
                      value={dob || ""}
                      onChange={handleChange}
                      id="dob"
                      className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    />
                  </div>
                </div>
                <div className="w-full px-3 sm:w-1/2">
                  <div className="mb-5">
                    <label
                      htmlFor="mobile"
                      className="mb-3 block text-base font-medium text-[#07074D]">
                      Mobile Number
                    </label>
                    <input
                      type="tel"
                      name="mobileNumber"
                      value={`+91${formData.mobileNumber || ""}`}
                      onChange={handleChange}
                      id="mobile"
                      readOnly
                      placeholder="Enter your phone number"
                      className="w-full appearance-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    />
                  </div>
                </div>
              </div>

              <div className="mb-5">
                <label
                  htmlFor="mobile"
                  className="mb-3 block text-base font-medium text-[#07074D]">
                  Select Profession
                </label>
                <select
                  id="profession"
                  name="profession"
                  value={formData.profession || ""}
                  onChange={handleChange}
                  className="w-full  rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md">
                  <option value="Select Profession">Select Profession</option>
                  <option value="Student">Student</option>
                  <option value="Trainer">Trainer</option>
                  <option value="Entreprenuer">Entreprenuer</option>
                  <option value="Homemaker">Homemaker</option>
                  <option value="Freelacncer">Freelacncer</option>
                  <option value="Working Professional">
                    Working Professional
                  </option>
                </select>
              </div>

              <div className="mb-5">
                {" "}
                <label
                  htmlFor="intrest"
                  className="mb-3 block text-base font-medium text-[#07074D]">
                  Select Interest
                </label>
                <div className="grid grid-cols-2 mb-5 md:grid-cols-3">
                  {interestsData.map((interest) => (
                    <div key={interest.id} className="flex items-center mb-4">
                      <input
                        type="checkbox"
                        id={interest.id}
                        checked={
                          selectedInterests !== undefined
                            ? selectedInterests.includes(interest.name)
                            : false
                        }
                        onChange={() => handleCheckboxChange(interest.id)}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        htmlFor={interest.name}
                        className="ms-2 text-sm font-medium text-gray-400 dark:text-gray-500">
                        {interest.name}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="mb-5">
                <label
                  htmlFor="mobile"
                  className="mb-3 block text-base font-medium text-[#07074D]">
                  Bio
                </label>
                <input
                  type="text"
                  name="bio"
                  value={formData.bio || ""}
                  onChange={handleChange}
                  placeholder="Tell something about you"
                  id="bio"
                  className="w-full appearance-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                />
              </div>

              <div className="flex justify-between">
                <button
                  onClick={handleSubmit}
                  className="hover:shadow-form rounded-md bg-blue-400 py-3 px-8 text-center text-base font-semibold text-white outline-none">
                  Save
                </button>
                <button
                  onClick={handleBack}
                  className="hover:shadow-form rounded-md bg-blue-400 py-3 px-8 text-center text-base font-semibold text-white outline-none">
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
     
    </>
  );
}

export default EditProfile;
