// addressSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiKey from '../../assets/apikey'
const key = apiKey;
export const fetchAddressDetails = createAsyncThunk(
    'address/fetchAddressDetails',
    async ({ lat,lng }, { rejectWithValue }) => {
       
        try {
            const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${key}`;
            const response = await fetch(url);
            const data = await response.json();

            if (data.status === 'OK') {
                const addressComponents = data.results[0].address_components;
                let addressDetails = {
                    postalCode: '',
                    state: '',
                    city:''
                };

                addressComponents.forEach(component => {
                    if (component.types.includes('postal_code')) {
                        addressDetails.postalCode = component.long_name;
                    }
                    if (component.types.includes('administrative_area_level_1')) {
                        addressDetails.state = component.long_name;
                    }
                    if (component.types.includes('locality')) {
                        addressDetails.city = component.long_name;
                    }
                });

                return addressDetails;
            } else {
                console.error('Geocoding API error:', data.status);
                return rejectWithValue('Geocoding API error: ' + data.status);
            }
        } catch (error) {
            console.error('Error fetching address details:', error);
            return rejectWithValue('Error fetching address details: ' + error.message);
        }
    }
);

const addressSlice = createSlice({
    name: 'address',
    initialState: {
        loading: false,
        addressDetails: {},
        error: ''
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAddressDetails.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchAddressDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.addressDetails = action.payload;
                state.error = '';
            })
            .addCase(fetchAddressDetails.rejected, (state, action) => {
                state.loading = false;
                state.addressDetails = {};
                state.error = action.payload;
            });
    }
});

export default addressSlice.reducer;
