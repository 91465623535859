import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import { sendOTP } from '../../../redux/slice/SIgnUpSlice/SendOtpSlice';
import { verifyOTP } from '../../../redux/slice/SIgnUpSlice/verifyOTPSlice';
import sociverse_app_logo from '../../../assets/sociverse_app_logo.png'
import Alert from '../../../Features/Alert'
import { FaRegUserCircle } from "react-icons/fa";
import { FaArrowCircleRight } from "react-icons/fa";
import { MdVerifiedUser, MdVerified } from "react-icons/md";
import SignInfo from './SignInfo';
function SignUp() {
  const[isvisible , setVisible]=useState(false);
  const [shouldSendOtp, setShouldSendOtp] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [loginType, setLoginType] = useState('');
  const [isSendOtpButtonDisabled, setSendOtpIsButtonDisabled] = useState(false);

  const [otp , setOTP]=useState("");
  const [alertMessage, setAlert]=useState('')
  const navigate=useNavigate();
  const {success}=useSelector((state)=>state.Sendotp
  )
  const { verified } = useSelector((state) => state.Verifyotp);
  const dispatch =useDispatch();

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);


    // Check if the value is an email or phone number
    if (isEmail(value)) {
      setLoginType("email");
      setShouldSendOtp(true);
      // Perform email related actions here
    } else if (isPhoneNumber(value)) {
      setLoginType("phoneNumber");
      setShouldSendOtp(true);
      // Perform phone number related actions here
    } else {
      console.log('Invalid input:', value);
      setShouldSendOtp(false)
      // Handle invalid input if necessary
    }
  };

  const isEmail = (value) => {
    // Simple email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };

  const isPhoneNumber = (value) => {
    // Simple phone number validation regex
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(value);
  };

  const handleSendOTP=()=>{
    setSendOtpIsButtonDisabled(true);
    setTimeout(() => {
      setSendOtpIsButtonDisabled(false);
    }, 50000);
    dispatch(sendOTP({ loginType, inputValue })).then ((response)=>{
      if (loginType === 'phoneNumber')
      setAlert('Please Enter OTP Sent To Your Phone Number');
    else if (loginType === 'email') 
      setAlert('Please Enter OTP Sent To Your Email');
    });
    

  }
const OTP=(e)=>{
setOTP(e.target.value);
}
const getPlaceholder = () => {
  if (loginType === 'phoneNumber')
      return 'Please Enter OTP Sent To Your Phone Number';
  else if (loginType === 'email') 
    return 'Please Enter OTP Sent To Your Email';

}

const handleVerifyOTP=()=>{
  if(otp){ dispatch(verifyOTP({ otp,loginType, inputValue })).then((response) => {
  if (response.type === "auth/verifyOTP/fulfilled") {
  setVisible(true);
  }
  else setAlert("Enter Valid otp")
  });
}
 
}

  return (
    <>
      {isvisible ? (
        <SignInfo userContactDetails ={inputValue} contactType={loginType} />
      ) : (
        <div className="right relative w-full h-[100%]  md:w-[60%] shadow-lg flex flex-col justify-center items-center ">
          {success ? (
            alertMessage ? (
              <div className="w-full absolute top-0 ">
                <Alert alert={alertMessage} />
              </div>
            ) : (
              ""
            )
          ) : (
            ""
          )}
          <div className="w-full p-6 md:w-[60%]">
            <div className="flex flex-col gap-12">
              <div className="logo justify-center items-center flex">
                <img
                  className="h-50 w-40 bg-transparent"
                  src={sociverse_app_logo}
                  alt=""
                />
              </div>
              <div className="relative flex">
                <div className="absolute left-0 top-[0.4rem] p-2 h-10 ">
                  <FaRegUserCircle />
                </div>
                <input
                  className="appearance-none bg-inherit border-b-2 border-black p-2 pl-10 w-full "
                  type="text"
                  name="contact"
                  value={inputValue}
                  onChange={handleInputChange}
                  placeholder="Email/Phone Number"
                />
                <button
                  className=" text-[#549CE6] absolute right-0 p-2 text-2xl"
                  disabled={!shouldSendOtp || isSendOtpButtonDisabled}
                  onClick={handleSendOTP}>
                  <FaArrowCircleRight />
                </button>
              </div>

              <div className="relative flex">
                <div className="absolute left-0 top-[0.4rem] p-2">
                  <MdVerified />
                </div>
                <input
                  className="appearance-none  border-b-2 bg-inherit border-black p-2 pl-10 w-full"
                  name="otp"
                  placeholder={getPlaceholder()}
                  value={otp}
                  onChange={OTP}
                />
              </div>
              <Link to={verified ? "/sign-up/detail" : ""}>
                <div className="flex justify-center">
                  <button
                    className="flex items-center justify-center font-bold text-sm md:w-36 md:text-lg py-2 px-4 hover:bg-sky-400 hover:rounded-xl hover:text-white"
                    onClick={handleVerifyOTP}>
                    <MdVerifiedUser /> Verify
                  </button>
                </div>
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SignUp