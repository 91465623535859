import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk to fetch professions
export const fetchProfession = createAsyncThunk(
    'profession/fetchProfession',
    async () => {
        const response = await axios.get(
            'https://api.sociverse.in/resources/professions'
        );
        return response.data.result;
    }
);

const professionSlice = createSlice({
    name: 'profession',
    initialState: {
        loading: false,
        profession: [],
        error: '',
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProfession.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchProfession.fulfilled, (state, action) => {
                state.loading = false;
                state.profession = action.payload;
                state.error = '';
            })
            .addCase(fetchProfession.rejected, (state, action) => {
                state.loading = false;
                state.profession = [];
                state.error = action.error.message || 'Failed to fetch professions';
            });
    },
});

export default professionSlice.reducer;
