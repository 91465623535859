import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";
import moment from "moment";
import { FaLocationDot } from "react-icons/fa6";
import noImg from "../assets/noImg.jfif";

export const EventCard = ({ event }) => {
  const [startdate, setStardate] = useState(null);

  useEffect(() => {
    const date = new Date(event.startTime);
    setStardate(moment(date).format("DD MMM YYYY, hh:mm A"));
  }, [event]);

  const sanitizeHtml = (html) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  return (
    <div className="flex flex-col bg-white border shadow-md rounded-xl overflow-hidden">
      {!event.attachmentLink ? (
        <img className="w-full h-52 object-cover" src={noImg} alt="Event" />
      ) : (
        <Link to={`/Event/${event.uuid}`}>
          <img
            className="w-full h-52 object-cover"
            src={event.attachmentLink}
            alt="Event"
          />
        </Link>
      )}

      <div className="p-4 md:p-5">
        <h3 className="text-lg font-bold text-gray-800 line-clamp-1">
          {event.name}
        </h3>

        <div className="flex justify-between items-center mt-2">
          <div className="flex items-center gap-1 w-4/6">
            <FaLocationDot className="text-green-500" />
            <p className="text-sm truncate text-gray-500">{event.address}</p>
          </div>
          <div className="flex items-center justify-center w-2/6">
            <div className="bg-sky-100 text-blue-600 p-1 rounded-lg text-xs text-center">
              {event.eventMode}
            </div>
          </div>
        </div>

        <div className="flex justify-between items-center mt-3">
          <div>
            <p className="text-xs font-bold md:text-base">Start Date</p>
            <p className="text-gray-400">{startdate}</p>
          </div>
        </div>

        <p className="font-bold mt-3">Description</p>
        <div className="text-xs text-gray-500 line-clamp-3 mt-2">
          {typeof event.summary === "string" && event.summary.includes("<") ? (
            <div
              dangerouslySetInnerHTML={sanitizeHtml(event.summary)}
              className="leading-5"
            />
          ) : (
            <p className="leading-5">{event.summary}</p>
          )}
        </div>

        <Link
          to={`/Event/${event.uuid}`}
          className="mt-4 inline-block py-2 px-4 bg-[#55D3FC] text-white text-sm font-semibold rounded-lg hover:bg-blue-700">
          See More
        </Link>
      </div>
    </div>
  );
};