import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../../../redux/slice/ResetPassSlice";
import { RiLockPasswordLine } from "react-icons/ri";
import sociverse_app_logo from "../../../assets/sociverse_app_logo.png";
import AlertDialogBox from "../../../Features/AlertDialogBox";
import Alert from "../../../Features/Alert";
import { FaRegUserCircle } from "react-icons/fa";
import { FaArrowCircleRight } from "react-icons/fa";
import { MdVerifiedUser, MdVerified } from "react-icons/md";
function ResetPass({email}) {
  const [password, setpassword] = useState("");
  const [reSetPass, setresSetPass] = useState("");
  const [alertMessage, setAlert] = useState("");
  const { status } = useSelector((state) => state.resetPass);
  const dispatch = useDispatch();
 


  return (
    <div className="w-full flex justify-center items-center">
      <div className="w-full p-6 md:w-[60%]">
        <div className="flex flex-col gap-12">
          <div className="logo justify-center items-center flex">
            <img
              className="h-50 w-40 bg-transparent"
              src={sociverse_app_logo}
              alt=""
            />
          </div>
          <div className="relative flex">
            <div className="absolute left-0 top-[0.4rem] p-2">
              <RiLockPasswordLine />
            </div>
            <input
              className="appearance-none bg-inherit border-b-2 border-black p-2 pl-10 w-full "
              type="password"
              name="password"
              value={password}
              onChange={(e) => setpassword(e.target.value)}
              placeholder="Enter Password"
            />
          </div>

          <div className="relative flex">
            <div className="absolute left-0 top-[0.4rem] p-2">
              <MdVerified />
            </div>
            <input
              className="appearance-none  border-b-2 bg-inherit border-black p-2 pl-10 w-full"
              name="password"
              placeholder="Enter Password Again"
              value={reSetPass}
              onChange={(e) => setresSetPass(e.target.value)}
            />
          </div>
          {password === reSetPass ? (
            ""
          ) : reSetPass !== "" ? (
            <p className="text-red-600">Passwords do not match</p>
          ) : (
            ""
          )}

          <div className="flex justify-center">
            <button>
              <AlertDialogBox
                title={"Reset"}
                heading={"Your password has been reset kindly log in"}
                id={email}
                pass={reSetPass}
                Login={"Login"}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPass;
