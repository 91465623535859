import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const ReportData = createAsyncThunk(
    'report',
    async ({ accessToken, id, report }, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `https://api.sociverse.in/events/${id}/report`,
                {   
                    reason: report
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    }
                }
            );
            return response.data.message;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const ReportSlice = createSlice({
    name: 'Report',
    initialState: {
        loading: false,
        error: null,
        Report: '',
    },

    extraReducers: (builder) => {
        builder
            .addCase(ReportData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(ReportData.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.Report = action.payload;
            })
            .addCase(ReportData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default ReportSlice.reducer;
