// src/features/userEvents/userEventsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Define the async thunk
export const removeUser = createAsyncThunk(
    'userEvent/removeUser',
    async ({ id, token, userIds }, { rejectWithValue }) => {
       
        try {

            const response = await axios.put(
                `https://api.sociverse.in/userEvent/${id}/remove`,
                { userIds:userIds },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    
                }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


const removeUserSlice = createSlice({
    name: 'removeUser',
    initialState: {
        status: false,
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(removeUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(removeUser.fulfilled, (state, action) => {
                state.loading = false;
                state.status = true
            })
            .addCase(removeUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default removeUserSlice.reducer;
