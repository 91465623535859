import React from "react";
import { MdOutlineArrowOutward } from "react-icons/md";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="bg-black text-white p-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row justify-between items-start gap-8">
          {/* Left Section - Contact Info */}
          <div className="flex flex-col gap-4">
            <h2 className="text-2xl md:text-[40px] font-semibold mb-4">Stay in Touch</h2>
            <p className="text-sm md:text-[20px] font-light">Contact</p>
            <p className="text-sm md:text-[20px] font-light">
              Mahaveer Ranches, Hosa Road,
              <br />
              Bengaluru, Karnataka 560100
            </p>
            <div className="flex gap-4 mt-4">
              <a href="https://facebook.com" className="hover:text-blue-500">
                <FaFacebook size={24} />
              </a>
              <a href="https://twitter.com" className="hover:text-sky-400">
                <FaTwitter size={24} />
              </a>
              <a href="https://instagram.com" className="hover:text-pink-500">
                <FaInstagram size={24} />
              </a>
              <a href="https://linkedin.com" className="hover:text-blue-600">
                <FaLinkedin size={24} />
              </a>
            </div>
          </div>

          {/* Middle Section - Quick Links (Optional for future use) */}
          <div className="hidden md:flex flex-col gap-2">
            <h3 className="text-lg font-semibold mb-2">Quick Links</h3>
            <a href="#" className="text-sm hover:underline">
              About Us
            </a>
            <a href="#" className="text-sm hover:underline">
              Help Center
            </a>
            <a href="#" className="text-sm hover:underline">
              Privacy Policy
            </a>
            <a href="#" className="text-sm hover:underline">
              Terms of Service
            </a>
          </div>

          {/* Right Section - App Links */}
          <div className="flex flex-col gap-4 md:items-end">
            <h3 className="text-lg font-semibold mb-4 md:mb-0">Get the App</h3>
            <div className="flex flex-col gap-3">
              <a
                href="https://play.google.com/store/apps/details?id=com.av.findhope&hl=en_US&pli=1"
                className="flex items-center px-4 py-3 bg-blue-600 hover:bg-blue-700 text-white text-sm md:text-lg rounded-lg transition duration-300">
                <MdOutlineArrowOutward className="mr-3" />
                Download Android App
              </a>
              <a
                href="https://apps.apple.com/in/app/sociverse-the-event-hub/id6471382597"
                className="flex items-center px-4 py-3 bg-green-600 hover:bg-green-700 text-white text-sm md:text-lg rounded-lg transition duration-300">
                <MdOutlineArrowOutward className="mr-3" />
                Download iOS App
              </a>
            </div>
          </div>
        </div>

        {/* Bottom Section */}
        <div className="mt-8 border-t border-gray-700 pt-4 flex flex-col md:flex-row justify-between items-center text-xs text-gray-400">
          <p>&copy; {new Date().getFullYear()} Sociverse. All rights reserved.</p>
          <div className="flex space-x-4 mt-4 md:mt-0">
            <a href="#" className="hover:underline">
              Privacy Policy
            </a>
            <a href="#" className="hover:underline">
              Terms of Service
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
