import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../redux/slice/EventDataSlice";
import { EventCard } from "../components/EventCard";
import Loader from "../components/Loader";
import Filter from "../components/Filter";
import { updateFilters } from "../redux/resourcesSlice/filterData";

const EventListing = () => {
  const dispatch = useDispatch();
  const { loading, error, Eventdata } = useSelector((state) => state.Eventdata);
  const filter = useSelector((state) => state.filterData);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
const filterObj={...filter,radius :latitude&&longitude ?200:-1}

  const accessToken = localStorage.getItem("accessToken");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.error("Error getting geolocation:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  useEffect(() => {
      dispatch(
        fetchData({
          latitude,
          longitude,
          accessToken,
          filterObj,
          startDate,
          endDate,
        })
      );
   
  }, [ latitude, longitude, accessToken, filter]);

  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center h-screen w-full">
          <Loader />
        </div>
      ) : Eventdata && Eventdata.length > 0 ? (
        <div>
          <div className="flex justify-center mt-6 my-6 min-h-screen">
            <div className="container mx-auto px-4 lg:px-8">
              <div className="grid grid-cols-1 p-2 md:grid-cols-3 gap-6">
                {Eventdata.map((event, index) => (
                  <div key={index} className="flex justify-center p-2">
                    <div className="max-w-xs md:max-w-full">
                      <EventCard event={event} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex min-h-screen items-center justify-center">
          <h1 className="text-3xl font-bold">
            No Events Found 
          </h1>
        </div>
      )}
    </>
  );
};

export default EventListing;
