import React from "react";
import { useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfileData } from "../redux/slice/ProfileSlice";
import { Link, useNavigate } from "react-router-dom";
import Loader from "./Loader";
export const ProfileCard = () => {
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem("accessToken");
  const { profileData, status, error } = useSelector((state) => state.profile);
  const [age, setAge] = useState(0);
  const navigate=useNavigate()
  const calculateAge = () => {
    const dobDate = new Date(profileData.dob);
    const currentDate = new Date();
    const dobYear = dobDate.getFullYear();
    const currentYear = currentDate.getFullYear();
    const calculatedAge = currentYear - dobYear;
    setAge(calculatedAge);
  };
  useEffect(() => {
    dispatch(fetchProfileData(accessToken));
  }, []);
  useEffect(() => {
    if(profileData){
    calculateAge();}
  }, [profileData]);

  const handleButton=()=>{
    navigate("/Login");
  }
  
  return (
    <>
      {status === "loading" ? (
        <div className="flex justify-center items-center h-screen w-full">
          <Loader />
        </div>
      ) : (
        <>
          {error != "Rejected" ? (
            <>
              <div className="p-16  min-h-screen bg-sky-50">
                <div className="absolute  bg-black opacity-50"></div>
                <div className="p-8 bg-white shadow-2xl rounded-xl mt-24">
                  <div className="grid grid-cols-1 md:grid-cols-3">
                    <div className="grid grid-cols-2 md:grid-cols-3 text-center order-last md:order-first mt-20 md:mt-0">
                      <div className="md:col-span-1">
                        <p className="font-bold text-gray-700 text-xl">
                          {profileData.userJoinedEventsCount}
                        </p>
                        <p className="text-gray-400">Joind Events</p>
                      </div>
                      <div className="md:col-span-1">
                        <p className="font-bold text-gray-700 text-xl">
                          {profileData.userCreatedEventsCount}
                        </p>
                        <p className="text-gray-400">Created Events</p>
                      </div>
                    </div>
                    <div className="relative">
                      <div className=" w-48 h-48 bg-indigo-100 mx-auto rounded-full shadow-2xl absolute inset-x-0 top-0 -mt-32 flex items-center justify-center text-indigo-500">
                        <img
                          className="w-48 h-48 rounded-full object-cover"
                          src={profileData.profilePicture}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="space-x-8 flex justify-center mt-32 md:mt-0 md:justify-center">
                      <button className="text-white py-2 px-4 uppercase rounded bg-blue-400 hover:bg-blue-500 shadow hover:shadow-lg font-medium transition transform hover:-translate-y-0.5">
                        <Link to="/profile/edit">Edit Profile</Link>
                      </button>
                    </div>
                  </div>
                  <div className="mt-20 text-center border-b pb-12">
                    <h1 className="text-4xl font-medium text-[#60A5FA]">
                      {profileData.name},{" "}
                      <span className="font-light text-gray-500">{age}</span>
                    </h1>
                    <p className=" font-medium text-gray-600 mt-3">
                      {profileData.profession} | {profileData.email}
                    </p>
                    <div className="mt-8 text-lg font-bold text-black">
                      Interest -{" "}
                      {profileData && profileData.interests ? (
                        profileData.interests.map((interest, index) => (
                          <span key={index} className="text-[#60A5FA]">
                            {interest}
                            {index !== profileData.interests.length - 1 && ", "}
                          </span>
                        ))
                      ) : (
                        <span>No interests found</span>
                      )}
                    </div>
                  </div>
                  <div className="mt-12 flex flex-col justify-center">
                    <p className="text-gray-600 text-center font-normal lg:px-16">
                      {profileData.bio}
                    </p>
                    
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="h-screen flex justify-center items-center">
              <div className="flex items-center flex-col gap-6">
                {" "}
                <h1 className="font-bold text-3xl">{error} | Kindly Login</h1>
                <button
                  type="button"
                  onClick={handleButton}
                  className="focus:outline-none text-white bg-[#60A5FA] hover:bg-[#60A5FA] focus:ring-4  font-medium rounded-lg text-sm px-5 py-2.5 mb-2 ">
                  Login
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
