import React, { useEffect,useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchFilters } from "../redux/resourcesSlice/filtersSlice";
import { updateFilters } from "../redux/resourcesSlice/filterData";
import _ from "lodash";
import { IoSearch } from "react-icons/io5";

const Filter = () => {
  const dispatch = useDispatch();
  const { filter } = useSelector((state) => state.filters);
  const [filters, setFilters] = useState({
    "eventDate": "",
    "eventMode": "",
    "eventType": "",
    // "radius": ,
    "searchKeyword": "",
  });

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
    dispatch(updateFilters({ [name]: value }));
  };

  useEffect(() => {
    dispatch(fetchFilters());
  }, [dispatch]);
   const debouncedDispatch = useCallback(
     _.debounce((value) => {
       dispatch(updateFilters({ searchKeyword: value }));
     }, 500),
     [dispatch]
   );

   const handleSearch = (e) => {
     const { value } = e.target;
     setFilters((prevFilters) => ({
       ...prevFilters,
       searchKeyword: value,
     }));
     debouncedDispatch(value);
   };

  return (
    <div className="flex   md:flex-row justify-center items-center  w-full gap-2">
      {filter && filter["Event Type"] && (
        <select
          name="eventMode"
          onChange={handleOnChange}
          className="w-4  md:w-auto md:h-10 border-2 border-sky-500 focus:outline-none focus:border-sky-500 text-sky-500 text rounded px-2 h-8 flex justify-center items-center   md:px-2  md:py-1 md:tracking-wider">
          {/* <option value="All">All</option> */}
          {filter["Event Type"].map((filterItem, index) => (
            <option
              className=" text-xs text-center hover:bg-sky-500 md:text-lg"
              value={filterItem.toUpperCase()}
              key={index}>
              {filterItem}
            </option>
          ))}
        </select>
      )}
      <div className="flex w-full md:w-3/4">
        <input
          type="text"
          name="searchKeyword"
          onChange={handleSearch}
          placeholder="Search for events you like"
          className="w-full text-black px-2 h-8 md:h-10 rounded-l border-2 border-sky-500 focus:outline-none focus:border-sky-500"
        />
      </div>
    </div>
  );
};

export default Filter;
