import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchEventDetail = createAsyncThunk(
    'eventDetail/fetchEventDetail',
    async ({id,token}, { rejectWithValue }) => {
        try {
          
            const response = await axios.get(`https://api.sociverse.in/events/${id}`,
               );
            return response.data.result;
        } catch (error) {

            return rejectWithValue(error.response.data);
        }
    }
);

const EventDetailSlice = createSlice({
    name: 'EventDetail',
    initialState: {
        loading: true,
        error: null,
        EventDetail: null,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchEventDetail.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchEventDetail.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.EventDetail = action.payload;
            })
            .addCase(fetchEventDetail.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default EventDetailSlice.reducer;