import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk to fetch event detail
export const updateEvent = createAsyncThunk(
    'eventDetail/fetchEventDetail',
    async ({ eventData, uuid , token }, { rejectWithValue }) => {
        
        try {
            const response = await axios.put(`https://api.sociverse.in/events/${uuid}`, eventData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            return response.data.result;
        } catch (error) {
            // Handle errors here
            return rejectWithValue(error.response.data);
        }
    }
);

const updateEventSlice = createSlice({
    name: 'eventDetail',
    initialState: {
        eventDetail: null,
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(updateEvent.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateEvent.fulfilled, (state, action) => {
                state.loading = false;
                state.eventDetail = action.payload;
            })
            .addCase(updateEvent.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default updateEventSlice.reducer;
