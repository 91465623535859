import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { acceptEvent } from "../redux/slice/AcceptRequestSlice";
import { useParams } from "react-router-dom";
import { rejectEvent } from "../redux/slice/RejectRequestSlice";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { fetchPendingRequests } from "../redux/slice/EventPendingRequestSlice";
import { fetchEventDetail } from "../redux/slice/SingleEventSlice";
import { removeUser } from "../redux/slice/RemoveAttaendieSlice";

const RequestListing = ({ data, heading, rejectbtn, profileID, EventDetailID }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [userIds, setCheckedUsers] = useState([]);
  const { Request } = useSelector((state) => state.PendingRequest);
  const { Reject } = useSelector((state) => state.RejectRequest);
  const [rejectReason, setReason] = useState("");
  const navigate = useNavigate();

  const handleCancel = () => {
    setIsOpen(false); // Close the modal without reporting
  };
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");
  const { id } = useParams();

  const handleCheckboxChange = (userId) => {
    if (userIds.includes(userId)) {
      setCheckedUsers(userIds.filter((id) => id !== userId));
    } else {
      setCheckedUsers([...userIds, userId]);
    }
  };
  const handleSelectAllChange = (e) => {
    if (e.target.checked) {
      const allUserIds = data.map((request) => request.id);
      setCheckedUsers(allUserIds);
    } else {
      setCheckedUsers([]);
    }
  };
  const handleAccept = () => {
    dispatch(acceptEvent({ id, token, userIds })).then((response) => {
      dispatch(fetchEventDetail({ id, token }));
    });
  };

  const handleReject = () => {
    setIsOpen(true);
  };

  const handleConfirm = () => {
    if (rejectbtn === "Reject") {
      if (rejectReason !== "") {
        dispatch(rejectEvent({ id, token, userIds })).then((response) => {
          dispatch(fetchEventDetail({ id, token }));
          setIsOpen(false);
        });
      } else {
        alert("Please write the reason");
      }
    } else {
      if (rejectReason !== "") {
        dispatch(removeUser({ id, token, userIds })).then((response) => {
          dispatch(fetchEventDetail({ id, token }));
          setIsOpen(false);
        });
      } else {
        alert("Please write the reason");
      }
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      {data && (
        <div>
          <button
            disabled={token&&(profileID===EventDetailID) ? false : true}
            onClick={toggleModal}
            data-modal-target="crud-modal"
            data-modal-toggle="crud-modal"
            type="button"
            className="flex flex-col">
            <div className="flex gap-1 mt-1">
              {data.map((request, index) => (
                <img
                  key={index}
                  className="w-8 h-8 object-cover rounded-full"
                  src={request.profilePicture}
                  alt=""
                />
              ))}
            </div>
          </button>
          {isModalOpen && (
            <div
              id="authentication-modal"
              tabIndex="-1"
              aria-hidden="true"
              className="fixed inset-0 z-50 flex justify-center items-center w-full h-full overflow-y-auto overflow-x-hidden bg-gray-800 bg-opacity-50">
              <div className="relative p-4 w-full max-w-md ">
                {/* Modal content */}
                <div className="relative rounded-lg shadow bg-sky-50">
                  {/* Modal header */}
                  <div className="flex justify-between p-4 md:p-5 rounded-t dark:border-gray-600">
                    <h3 className="text-xl font-semibold text-black">
                      {heading}
                    </h3>
                    <button
                      type="button"
                      onClick={closeModal}
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                      <RxCross2 />
                      <span className="sr-only">Close modal</span>
                    </button>
                  </div>
                  <div className="p-4 h-[24rem] overflow-y-auto">
                    <div className="mb-2">
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          checked={userIds.length === data.length}
                          onChange={handleSelectAllChange}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label className="ml-2 text-sm text-black">
                          Select All
                        </label>
                      </div>
                    </div>
                    {data.map((request, index) => (
                      <div key={index} className="mb-2">
                        <div className="flex items-center">
                          <input
                            type="checkbox"
                            checked={userIds.includes(request.id)}
                            onChange={() => handleCheckboxChange(request.id)}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label className="flex items-center ml-3">
                            <img
                              className="w-8 h-8 object-cover rounded-full"
                              src={request.profilePicture}
                              alt={request.name}
                            />
                            <div className="ml-3">
                              <p className="text-sm text-black">
                                {request.name}
                              </p>
                              <p className="text-sm text-black">
                                {request.email}
                              </p>
                            </div>
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* Modal body */}
                  <div className="p-4 flex flex-col justify-end md:p-5">
                    <div className="">
                      <div className="flex justify-between">
                        {rejectbtn === "Reject" && (
                          <button
                            type="button"
                            onClick={handleAccept}
                            className="text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-green-400">
                            Accept
                          </button>
                        )}
                        <button
                          type="button"
                          onClick={handleReject}
                          className="text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-orange-700">
                          {rejectbtn}
                        </button>
                        {isOpen && (
                          <div
                            id="crud-modal"
                            tabIndex={-1}
                            aria-hidden="true"
                            className="fixed top-0 right-0 bottom-0 left-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                            <div className="relative p-4 w-full max-w-[17rem]">
                              {/* Modal content */}
                              <div className="relative bg-white rounded-lg shadow">
                                <button
                                  type="button"
                                  className="text-blue-400 bg-transparent hover:bg-blue-400 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center absolute right-0"
                                  onClick={() => setIsOpen(false)}>
                                  <RxCross2 />
                                  <span className="sr-only">Close modal</span>
                                </button>
                                <div className="flex flex-col gap-2 items-center justify-between p-4 md:p-5 border-b rounded-t">
                                  <p className="text-xs">
                                    Please write some reason
                                  </p>
                                  <div className="w-full">
                                    <div className="">
                                      <textarea
                                        id="description"
                                        rows={2}
                                        value={rejectReason}
                                        onChange={(e) =>
                                          setReason(e.target.value)
                                        }
                                        className="block p-2.5 w-full text-sm border border-blue-400 bg-slate-100 rounded-md mt-2"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="mt-2 p-2 flex justify-end">
                                  <button
                                    type="button"
                                    onClick={handleConfirm}
                                    className="text-white rounded-xl text-xs p-2 text-center bg-[#60A5FA]">
                                    Confirm
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default RequestListing;
