    import React, { useEffect, useState } from 'react';

    const PaymentCallback = () => {
    
    const [paymentLinkStatus, setPaymentLinkStatus] = useState(null); // State to store payment link status
    const [isSuccess, setIsSuccess] = useState(false); // State to determine if payment was successful
          
    useEffect(() => {
        // Parse the query parameters from the URL
        const params = new URLSearchParams(window.location.search);

        // Extract the individual query parameters
        const razorpayPaymentId = params.get('razorpay_payment_id');
        const razorpayPaymentLinkId = params.get('razorpay_payment_link_id');
        const razorpayPaymentLinkReferenceId = params.get('razorpay_payment_link_reference_id');
        const razorpayPaymentLinkStatus = params.get('razorpay_payment_link_status');
        const razorpaySignature = params.get('razorpay_signature');

        // Log the extracted parameters
        console.log('Razorpay Payment ID:', razorpayPaymentId);
        console.log('Razorpay Payment Link ID:', razorpayPaymentLinkId);
        console.log('Razorpay Payment Link Reference ID:', razorpayPaymentLinkReferenceId);
        console.log('Razorpay Payment Link Status:', razorpayPaymentLinkStatus);
        console.log('Razorpay Signature:', razorpaySignature);

        // Set the payment link status and check if it's "paid"
        if (razorpayPaymentLinkStatus === 'paid') {
            setIsSuccess(true);
        }
        setPaymentLinkStatus(razorpayPaymentLinkStatus);


        // Call the API using the extracted razorpay_payment_link_reference_id
        const fetchPaymentLink = async () => {
            if (!razorpayPaymentLinkReferenceId) {
                console.error('Missing razorpay_payment_link_reference_id');
                return;
            }
            
            const url = `https://api.sociverse.in/payment/payment-link?razorpay_payment_link_reference_id=${razorpayPaymentLinkReferenceId}`;
            const token = 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJzdGF0dXNzYXZlcmFsbGluMUBnbWFpbC5jb20iLCJleHAiOjE3Mjg4MTM3MjMsImlhdCI6MTcyODcyNzMyM30.j5Jh6UBP3jMiWMRhz65iNBbGsp0iA2MVryilSTuQXvA';
    
            try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                }
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            const data = await response.json();
            console.log('Payment Link:', data);
            // Extract the callBackURL from the response and redirect to it
            const callBackUrl = data.result.callBackURL;
            if (callBackUrl) {
                console.log('Opening CallBack URL', callBackUrl);
                // Open the callBackURL in a new tab
                window.open(callBackUrl, '_self');
            } else {
                console.error('No callBackURL found in the response');
            }
            } catch (error) {
                console.error('Error fetching payment link:', error);
            }
        };
    
        fetchPaymentLink();

        // TODO validate signature and persist payment info

    }, []); // Empty dependency array to run effect once on component mount

    const styles = {
        container: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          backgroundColor: '#f0f4f8',
        },
        successBox: {
          textAlign: 'center',
          backgroundColor: '#fff',
          borderRadius: '10px',
          padding: '40px',
          maxWidth: '500px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        },
        icon: {
          backgroundColor: '#28a745',
          color: 'white',
          fontSize: '50px',
          borderRadius: '50%',
          padding: '20px',
          display: 'inline-block',
          marginBottom: '20px',
        },
        thankYou: {
          fontSize: '36px',
          color: '#28a745',
          marginBottom: '10px',
        },
        message: {
          fontSize: '20px',
          color: '#333',
          margin: '10px 0',
        },
        iconSuccess: {
          color: '#28a745',
          marginRight: '10px',
        },
        description: {
          fontSize: '16px',
          color: '#666',
          marginBottom: '20px',
        },
      };

    return (
        <div style={styles.container}>
      <div style={styles.successBox}>
        <div style={styles.icon}>
          <span role="img" aria-label="Thumbs up">👍</span>
        </div>
        <h1 style={styles.thankYou}>Thank you !</h1>
        <p style={styles.message}>
          <span style={styles.iconSuccess}>✔️</span> Payment is being processed.
        </p>
        <p style={styles.description}>
          Don't close this page or app, as we are processing your payment. Once payment is processed, we will route it to your home.
        </p>
      </div>
    </div>
    );
    };

    export default PaymentCallback;
