// slices/profileSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const updateProfile = createAsyncThunk(
    'profile/updateProfile',
    async (formData, thunkAPI) => {
        try {
            // Retrieve the JWT token from local storage
            const accessToken = localStorage.getItem('accessToken');

            // Configure Axios request headers to include the JWT token
            const config = {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            };
                    
            const response = await axios.put('https://api.sociverse.in/user/profile', formData, config);

            // Handle unsuccessful response
            if (response.status !== 200) {
                throw new Error(response.data.message || 'Failed to update profile');
            }

            // Return the updated profile data
            return formData;
        } catch (error) {
            // Handle error and return the rejected value
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

const initialState = {
    profileData: {
        name: '',
        email: '',
        profession: '',
        interests: [],
        // Other profile fields...
    },
    loading: false,
    error: null,
};

const updateSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(updateProfile.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateProfile.fulfilled, (state, action) => {
                state.loading = false;
                state.profileData = action.payload;
            })
            .addCase(updateProfile.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default updateSlice.reducer;
