
import { React, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchCreatedEvents } from "../redux/slice/CreatedEventSlice";
import { EventCard } from "./EventCard";

const OrganisedEvent = ({id}) => {
     const [Latitude, setLatitude] = useState(null);
     const [Longitude, setLongitude] = useState(null);
     const accessToken = localStorage.getItem("accessToken");
     const { createdEvents } = useSelector((state) => state.createdEvents);

     useEffect(() => {
       if (navigator.geolocation) {
         navigator.geolocation.getCurrentPosition(
           (position) => {
             setLatitude(position.coords.latitude);
             setLongitude(position.coords.longitude);
           },
           (error) => {
             console.error("Error getting geolocation:", error);
           }
         );
       } else {
         console.error("Geolocation is not supported by this browser.");
       }
     }, []);
     const dispatch = useDispatch();
     useEffect(() => {
      
         if (id) {
           dispatch(
             fetchCreatedEvents({ Latitude, Longitude, accessToken, id })
           );
         } else {
           dispatch(fetchCreatedEvents({ Latitude, Longitude, accessToken }));
         }
       
     }, [Latitude, Longitude, accessToken]);
  return (
    <div className="organise-events mt-8">
      <h1 className="text-2xl ml-2 font-medium">Organised Events</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 mt-7">
        {createdEvents && createdEvents.length > 0 ? (
          createdEvents.map((event, index) => (
            <div key={index} className="flex justify-center p-2">
              <div className="max-w-xs md:max-w-full">
                <EventCard event={event} />
              </div>
            </div>
          ))
        ) : (
          <h1>No Organized Events </h1>
        )}
      </div>
    </div>
  );
}

export default OrganisedEvent