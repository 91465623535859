import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { sendOTP } from "../../../redux/slice/SIgnUpSlice/SendOtpSlice";
import { verifyOTP } from "../../../redux/slice/SIgnUpSlice/verifyOTPSlice";
import sociverse_app_logo from "../../../assets/sociverse_app_logo.png";
import Alert from "../../../Features/Alert";
import { FaRegUserCircle } from "react-icons/fa";
import { FaArrowCircleRight } from "react-icons/fa";
import { MdVerifiedUser, MdVerified } from "react-icons/md";
import ResetPass from "./ResetPass";
function SendOtp() {
  const [isVisible,setVisible]=useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOTP] = useState("");
  const [alertMessage, setAlert] = useState("");
  const navigate = useNavigate();
  const { success } = useSelector((state) => state.Sendotp);
  const { verified} = useSelector((state) => state.Verifyotp);
  const dispatch = useDispatch();
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleSendOTP = () => {
    dispatch(sendOTP({ email })).then((response) => {
      setAlert("OTP Successfully Send To Your Email");
    });
  };
  const OTP = (e) => {
    setOTP(e.target.value);
  };
  const handleVerifyOTP = () => {
    if (otp) {
      dispatch(verifyOTP({ otp, email })).then((response) => {
        
        if (response.type === "auth/verifyOTP/fulfilled") {
          setVisible(true);
        }
        else setAlert("Enter Valid Otp")
        ;
      });
    }
  };
  return (
    <div className="right relative w-full h-[100%]  md:w-[60%] shadow-lg flex flex-col justify-center items-center ">
      {!isVisible && success && alertMessage!=="" ? (
        <div className="absolute top-0 w-full">
          <Alert alert={alertMessage} />
        </div>
      ) : (
        ""
      )}
      {isVisible ? (
        <ResetPass email={email} />
      ) : (
        <div className="w-full p-6 md:w-[60%]">
          <div className="flex flex-col gap-12">
            <div className="logo justify-center items-center flex">
              <img
                className="h-50 w-40 bg-transparent"
                src={sociverse_app_logo}
                alt=""
              />
            </div>
            <div className="relative flex">
              <div className="absolute left-0 top-[0.4rem] p-2 h-10 ">
                <FaRegUserCircle />
              </div>
              <input
                className="appearance-none bg-inherit border-b-2 border-black p-2 pl-10 w-full "
                type="email"
                name="email"
                value={email}
                onChange={handleEmail}
                placeholder="Email"
              />
              <button
                className=" text-[#549CE6] absolute right-0 p-2 text-2xl"
                onClick={handleSendOTP}>
                <FaArrowCircleRight />
              </button>
            </div>

            <div className="relative flex">
              <div className="absolute left-0 top-[0.4rem] p-2">
                <MdVerified />
              </div>
              <input
                className="appearance-none  border-b-2 bg-inherit border-black p-2 pl-10 w-full"
                name="otp"
                placeholder="Please Enter Otp Send To Your Email"
                value={otp}
                onChange={OTP}
              />
            </div>
            <div className="flex justify-center">
              <button
                className="flex items-center justify-center font-bold text-sm md:w-36 md:text-lg py-2 px-4 hover:bg-sky-400 hover:rounded-xl hover:text-white"
                onClick={handleVerifyOTP}>
                <MdVerifiedUser /> Verify
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SendOtp;
