import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import JoinedEvent from "./JoinedEvent";
import { Link, useParams } from "react-router-dom";
import { fetchHost } from "../redux/slice/HostInfoSlice";
import OrganisedEvent from "./OrganisedEvent";
import Loader from "./Loader";

const HostProfileCard = () => {
  const {id}=useParams();
  const token =localStorage.getItem("accessToken")
  const dispatch = useDispatch()
  const [age,setAge]=useState(null)
  const [isVisible,setVisible]=useState(false)
  const[joined,setJoined]=useState(false)
  const [created , setCreated]=useState(false)
   const { host,status } = useSelector(
      (state) => state.Host)
  const calculateAge = () => {
    const dobDate = new Date(host.dob);
    const currentDate = new Date();
    const dobYear = dobDate.getFullYear();
    const currentYear = currentDate.getFullYear();
    const calculatedAge = currentYear - dobYear;
    setAge(calculatedAge);
  };
  useEffect(() => {
    dispatch(fetchHost({id,token}))
  }, [id,dispatch]);
useEffect(() => {
  if (host) {
    calculateAge();
  }
}, [host]);

  return (
    <>
      {status === "loading" ? (
        <div className="h-screen flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        <div className="p-16 bg-sky-50">
          <div className="p-10  shadow-sky-700 bg-white ">
           {isVisible&& (<button
              onClick={() => setVisible(false)}
              className="bg-sky-400 p-2 mb-2 rounded-lg text-white">
              Back
            </button>)}
            {isVisible ? (
              <>
                {joined && <JoinedEvent title={"Joined Event"} id={id} />}
                {created && <OrganisedEvent id={id} />}
              </>
            ) : (
              <div className="p-8 shadow-lg rounded-xl mt-24">
                <div className="grid grid-cols-1 md:grid-cols-3">
                  <div className="grid grid-cols-2 md:grid-cols-3 text-center order-last md:order-first mt-20 md:mt-0">
                    <button
                      onClick={() => {
                        setVisible(true);
                        setJoined(true);
                      }}
                      className="md:col-span-1">
                      <p className="font-bold text-gray-700 text-xl">
                        {host.userJoinedEventsCount}
                      </p>
                      <p className="text-gray-400">Joined Events</p>
                    </button>
                    <button
                      onClick={() => {
                        setVisible(true);
                        setCreated(true);
                      }}
                      className="md:col-span-1">
                      <p className="font-bold text-gray-700 text-xl">
                        {host.userCreatedEventsCount}
                      </p>
                      <p className="text-gray-400">Created Events</p>
                    </button>
                  </div>
                  <div className="relative">
                    <div className="w-48 h-48 bg-indigo-100 mx-auto rounded-full shadow-2xl absolute inset-x-0 top-0 -mt-32 flex items-center justify-center text-indigo-500">
                      <img
                        className="w-48 h-48 rounded-full object-cover"
                        src={host.profilePicture}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-20 text-center border-b pb-12">
                  <h1 className="text-4xl font-medium text-[#60A5FA]">
                    {host.name},{" "}
                    <span className="font-light text-gray-500">{age}</span>
                  </h1>
                  <p className="font-medium text-gray-600 mt-3">
                    {host.profession} | {host.email}
                  </p>
                  <div className="mt-8 text-lg font-bold text-black">
                    Interest -{" "}
                    {host && host.interests ? (
                      host.interests.map((interest, index) => (
                        <span key={index} className="text-[#60A5FA]">
                          {interest}
                          {index !== host.interests.length - 1 && ", "}
                        </span>
                      ))
                    ) : (
                      <span>No interests found</span>
                    )}
                  </div>
                </div>
                <div className="mt-12 flex flex-col justify-center">
                  <p className="text-gray-600 text-center font-normal lg:px-16">
                    {host.bio}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default HostProfileCard;
