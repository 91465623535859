// src/features/events/eventsSlice.js

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

// Define the async thunk
export const acceptEvent = createAsyncThunk(
    'events/acceptEvent',
    async ({ id, token, userIds }, { rejectWithValue }) => {
        
        try {
            const response = await axios.put(
                `https://api.sociverse.in/events/${id}/action?action=ACCEPT`,
                { userIds },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            return response.data.message;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const eventsSlice = createSlice({
    name: 'PendingRequest',
    initialState: {
        Request: "",
        status: 'idle',
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(acceptEvent.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(acceptEvent.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.Request = action.payload;
            })
            .addCase(acceptEvent.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export default eventsSlice.reducer;
