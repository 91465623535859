import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchData = createAsyncThunk(
    'Eventdata/fetchData',
    async ({ latitude, longitude, filterObj }, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem('accessToken');

            var headers = {

            };
            if (latitude && longitude) {
                
                headers = {
                    ...headers, latitude: latitude, longitude: longitude
                }
            }
            if (token) {
                headers['Authorization'] = `Bearer ${token}`;
            }
            const response = await axios.post(
                'https://api.sociverse.in/events',
                filterObj,
                { headers }
            );

            return response.data.result;
        } catch (error) {
            return rejectWithValue(
                error.response && error.response.data
                    ? error.response.data
                    : error.message
            );
        }
    }
);


const EventdataSlice = createSlice({
    name: 'Eventdata',
    initialState: {
        loading: false,
        error: null,
        Eventdata: null,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchData.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.Eventdata = action.payload;
            })
            .addCase(fetchData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.Eventdata = null;
            });
    },
});

export default EventdataSlice.reducer;
