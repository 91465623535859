import React, { useState, useRef, useEffect } from 'react';
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import sociverse_app_logo from "../assets/sociverse_app_logo.png";
import Dropdown from "../Features/Dropdown";
import Filter from "./Filter";

const NavBar = () => {
  const location = useLocation();
  const path = location.pathname;
  const [nav, setNav] = useState(false);
  const [isLogin, setLogin] = useState(false);
  const navbar = useNavigate();
  
  const handleLogout = () => {
    localStorage.clear();
    navbar("/login");
  };

  const navRef = useRef();

  const handleClickOutside = (event) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setNav(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleNavigate = (link) => {
    navbar(`${link}`);
  };

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      setLogin(true);
    } else {
      setLogin(false);
    }
  });

  const handleNav = () => {
    setNav(!nav);
  };

  const navItems = [
    { id: 1, text: "Explore", link: "/" },
    { id: 2, text: "Home", link: "/home" },
    { id: 3, text: "Create", link: "/event/create" },
  ];

  return (
    <div className="bg-black text-white">
      <div className="flex justify-between items-center h-20 max-w-full mx-auto px-4">
        {/* Logo and Menu Icon */}
        <div className="flex items-center gap-2">
          <div onClick={handleNav} className="block lg:hidden">
            {!nav ? <AiOutlineMenu size={25} /> : <AiOutlineClose size={25} />}
          </div>
          <img className="h-10 w-10" src={sociverse_app_logo} alt="Sociverse" />
          <p className="font-bold text-lg md:text-2xl hidden md:block">
            Sociverse
          </p>
        </div>

        {/* Filter for Homepage */}
        {path === "/" && (
          <div className="hidden md:block w-full">
            <Filter />
          </div>
        )}

        {/* Desktop Navigation */}
        <ul className="hidden lg:flex items-center gap-4">
          {navItems.map((item) => (
            <Link
              key={item.id}
              to={item.link}
              className={`p-3 text-lg font-bold hover:bg-[#7DD3FC] ${
                path === item.link && "bg-[#7DD3FC]"
              } rounded-lg cursor-pointer transition-all duration-300`}
            >
              {item.text}
            </Link>
          ))}
          {isLogin ? (
            <div className="cursor-pointer">
              <Dropdown />
            </div>
          ) : (
            <Link
              to="/Login"
              className="p-3 text-lg font-bold hover:bg-[#7DD3FC] rounded-lg cursor-pointer transition-all duration-300"
            >
              Login
            </Link>
          )}
        </ul>

        {/* Mobile Navigation */}
        <ul
          ref={navRef}
          className={`fixed lg:hidden left-0 top-0 w-[70%] h-full bg-black text-white z-50 border-r border-gray-900 transition-transform duration-300 ${
            nav ? "translate-x-0" : "-translate-x-full"
          }`}
        >
          {/* Mobile Logo */}
          <div className="flex items-center p-4 border-b border-gray-600">
            <img className="h-10 w-10" src={sociverse_app_logo} alt="sociverse" />
            <p className="font-bold text-lg">Sociverse</p>
          </div>

          {/* Mobile Nav Items */}
          {navItems.map((item) => (
            <li
              key={item.id}
              onClick={() => {
                handleNavigate(item.link);
                setNav(false);
              }}
              className="p-4 border-b border-gray-600 hover:bg-sky-300 transition-all duration-300"
            >
              {item.text}
            </li>
          ))}
          {isLogin && (
            <Link to="/profile">
              <li className="p-4 border-b border-gray-600 hover:bg-sky-300 transition-all duration-300">
                Profile
              </li>
            </Link>
          )}
          {isLogin ? (
            <li
              onClick={handleLogout}
              className="p-4 border-b border-gray-600 hover:bg-sky-300 transition-all duration-300"
            >
              Logout
            </li>
          ) : (
            <Link to="/login">
              <li className="p-4 border-b border-gray-600 hover:bg-sky-300 transition-all duration-300">
                Login
              </li>
            </Link>
          )}
          {!isLogin && (
            <Link to="/sign-up">
              <li className="p-4 border-b border-gray-600 hover:bg-sky-300 transition-all duration-300">
                Sign Up
              </li>
            </Link>
          )}
        </ul>

        {/* Filter for Mobile View */}
        {path === "/" && (
          <div className="block md:hidden w-[80%]">
            <Filter />
          </div>
        )}
      </div>
    </div>
  );
};

export default NavBar;
