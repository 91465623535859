// profileSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchProfileData = createAsyncThunk(
    'profile/fetchProfileData',
    async (accessToken, thunkAPI) => {
        try {
            const response = await axios.get(
                "https://api.sociverse.in/user/profile",
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            localStorage.setItem("profileData", JSON.stringify(response.data.result));
            return response.data.result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

const profileSlice = createSlice({
    name: 'profile',
    initialState: {
        profileData: {},
        status: "idle",
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProfileData.pending, (state) => {
                state.status = "loading";
                state.error =null
                
            })
            .addCase(fetchProfileData.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.profileData = action.payload;
                state.error=null
              
            
            })
            .addCase(fetchProfileData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                
                

            });
    },
});

export default profileSlice.reducer;
