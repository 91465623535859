import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../redux/slice/AuthSlice';
import profileReducer from '../redux/slice/ProfileSlice';
import updateReducer from '../redux/slice/UpdateProfileSlice'
import EventdataReducer from '../redux/slice/EventDataSlice'
import filtersReducer from '../redux/resourcesSlice/filtersSlice'
import filtersDataReducer from '../redux/resourcesSlice/filterData'
import EventDetailReducer from '../redux/slice/SingleEventSlice';
import ReportReducer from '../redux/slice/Report'
import createdEventsReducer from '../redux/slice/CreatedEventSlice'
import joinedEventsReducer from '../redux/slice/JoinedEventSlice'
import PendingRequestReducer from '../redux/slice/EventPendingRequestSlice'
import RejectRequestReducer from '../redux/slice/RejectRequestSlice'
import interestsReducer from '../redux/resourcesSlice/Interest'
import ProfessionReducer from '../redux/resourcesSlice/profession'
import authSliceReducer from '../redux/slice/SIgnUpSlice/AuthDetailSlice'
import SendotpReducer from '../redux/slice/SIgnUpSlice/SendOtpSlice'
import VerifyotpReducer from '../redux/slice/SIgnUpSlice/verifyOTPSlice'
import TagReducer from '../redux/resourcesSlice/Tags'
import postEventReducer from '../redux/slice/CreateEventSlice'
import addressReducer from '../redux/resourcesSlice/EventAdressSlice'
import resetPassReducer from '../redux/slice/ResetPassSlice' 
import updateEventReducer from "../redux/slice/UpdateEventSlice"
import HostReducer from '../redux/slice/HostInfoSlice'

const store = configureStore({
    reducer: {
        auth: authReducer,
        profile: profileReducer,
        update: updateReducer,
        Eventdata: EventdataReducer,
        filters: filtersReducer,
        filterData: filtersDataReducer,
        EventDetail: EventDetailReducer,
        Report:ReportReducer,
        createdEvents: createdEventsReducer,
        joinedEvents: joinedEventsReducer,
        PendingRequest: PendingRequestReducer,
        RejectRequest: RejectRequestReducer,
        interests: interestsReducer,
        Profession: ProfessionReducer,
        authSlice: authSliceReducer,
        Sendotp: SendotpReducer,
        Verifyotp: VerifyotpReducer,
        Tag: TagReducer,
        postEvent: postEventReducer,
        address: addressReducer,
        resetPass: resetPassReducer,
        updateEvent: updateEventReducer,
        Host: HostReducer
    },
});

export default store;