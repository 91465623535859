import {React , useEffect, useState} from 'react'

const DateValidation = ({ startTime , endTime}) => {
    const [validationMessage, setValidationMessage] = useState('');
    const handleValidation = (startTime, endTime ) => {
        if (startTime === "NaN-NaN-NaNTNaN:NaN" || endTime ==="NaN-NaN-NaNTNaN:NaN")return true;
        const start = new Date(startTime);
        const end = new Date(endTime);

        if (start.toISOString() === end.toISOString()) {
            setValidationMessage('Start Time cannot be before End Time');
            return false;
        }

        if (start > end) {
            setValidationMessage('End date must be after start date.');
            return false;
        }

        if (
            start.getFullYear() === end.getFullYear() &&
            start.getMonth() === end.getMonth() &&
            start.getDate() === end.getDate() &&
            start >= end
        ) {
            setValidationMessage('If the dates are the same, the end time must be after the start time.');
            return false;
        }

        setValidationMessage('');
        return true;
    };

    useEffect(()=>{
        if(startTime&&endTime){
            handleValidation(startTime, endTime);
        }

    },[startTime,endTime])

  return (
    <>
          {validationMessage && (<div><p className='text-red-500'>{validationMessage}</p></div>)}
    </>
  )
}

export default DateValidation