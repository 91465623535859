import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Organiser = () => {
  const [prospects, setProspects] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedProficiencies, setSelectedProficiencies] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProspects = async () => {
      const url = "https://api.sociverse.in/events/organisers"; // Replace with your actual API URL

      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setProspects(data.result); // Access the 'result' array
      } catch (error) {
        console.error('Error fetching prospects:', error);
      }
    };

    fetchProspects();
  }, []);

  // Unique proficiencies for filtering (sorted in ascending order)
  const uniqueProficiencies = [...new Set(prospects.flatMap(p => p.interests))].sort();

  // Function to handle proficiency change
  const handleProficiencyChange = (proficiency) => {
    setSelectedProficiencies(prevState => {
      const newSelected = { ...prevState, [proficiency]: !prevState[proficiency] };

      // If unchecking, remove it from the object
      if (!newSelected[proficiency]) {
        delete newSelected[proficiency];
      }

      return newSelected;
    });
  };

  const styles = {
    container: {
      padding: '40px',
      textAlign: 'center',
      backgroundColor: '#ffffff',
      minHeight: '100vh',
    },
    heading: {
      fontSize: '2rem',
      marginBottom: '30px',
      color: '#333',
      fontWeight: '600',
      flexGrow: 1,
    },
    filterToggle: {
      marginLeft: '10px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
    },
    drawer: {
      position: 'fixed',
      top: 0,
      right: showFilter ? 0 : '-250px', // Hide drawer when not in use
      width: '250px',
      height: '100%',
      backgroundColor: '#f0f0f0',
      transition: 'right 0.3s ease',
      padding: '15px',
      boxShadow: '-2px 0 5px rgba(0,0,0,0.5)',
      zIndex: 2,
      textAlign: 'left', // Left align the drawer content
    },
    closeButton: {
      background: 'none',
      border: 'none',
      fontSize: '20px',
      cursor: 'pointer',
      color: '#333',
      float: 'right',
    },
    filterLabel: {
      fontSize: '1.5rem', // Larger font for filter label
      fontWeight: 'bold',
      marginBottom: '10px',
    },
    checkboxList: {
      margin: '10px 0',
      display: 'flex',
      flexDirection: 'column',
    },
    checkbox: {
      marginRight: '5px',
    },
    prospects: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '20px',
      justifyContent: 'center',
    },
    prospectCard: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: '#f9f9f9',
      border: '1px solid #e0e0e0',
      borderRadius: '10px',
      padding: '20px',
      width: '250px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      textAlign: 'center',
      position: 'relative',
      transition: 'transform 0.2s',
    },
    prospectImageContainer: {
      position: 'relative',
      marginBottom: '15px',
    },
    prospectImage: {
      borderRadius: '50%',
      width: '80px',
      height: '80px',
      objectFit: 'cover',
      border: '2px solid #007bff',
    },
    badge: {
      position: 'absolute',
      top: '0',
      right: '-15px',
      backgroundColor: '#6c757d',
      color: '#fff',
      borderRadius: '50%',
      padding: '8px 12px',
      fontSize: '0.875rem',
      fontWeight: 'bold',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      minWidth: '30px',
      textAlign: 'center',
    },
    prospectName: {
      fontSize: '1.25rem',
      marginBottom: '10px',
      color: '#007bff',
      fontWeight: '500',
    },
    proficiencyHeading: {
      fontSize: '0.9rem',
      color: '#000',
      margin: '5px 0',
      fontWeight: 'bold',
    },
    prospectInfo: {
      fontSize: '0.9rem',
      color: '#666',
      margin: '5px 0',
    },
    badgeTooltip: {
      position: 'absolute',
      top: '-25px',
      right: '0',
      backgroundColor: 'black',
      color: '#fff',
      padding: '3px 5px',
      borderRadius: '3px',
      fontSize: '0.7rem',
      visibility: 'hidden',
      opacity: 0,
      transition: 'visibility 0s, opacity 0.2s linear',
    },
    badgeHover: {
      visibility: 'visible',
      opacity: 1,
    },
    filterButton: {
      padding: '8px 12px',
      backgroundColor: '#007bff',
      color: '#fff',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      transition: 'background-color 0.3s',
      marginRight: '20px', // Matching margin with the cards
    },
    filterButtonHover: {
      backgroundColor: '#0056b3',
    },
  };

  // Function to handle card click and navigate to /host/:id
  const handleCardClick = (id) => {
    navigate(`/host/${id}`);
  };

  // Calculate filtered prospects based on selected proficiencies
  const filteredProspects = prospects.filter(prospect => 
    Object.keys(selectedProficiencies).length === 0 || 
    prospect.interests.some(interest => selectedProficiencies[interest])
  );

  // Sort filtered prospects by event created count in descending order
  const sortedProspects = filteredProspects.sort((a, b) => b.userCreatedEventsCount - a.userCreatedEventsCount);

  return (
    <div style={styles.container}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h1 style={styles.heading}>{sortedProspects.length} Organisers match your criteria</h1>
        <div style={styles.filterToggle}>
          <button
            style={styles.filterButton}
            onClick={() => setShowFilter(!showFilter)} // Toggle filter drawer visibility
          >
            Filter
          </button>
        </div>
      </div>

      <div style={styles.drawer}>
        <button style={styles.closeButton} onClick={() => setShowFilter(false)}>
          &times; {/* Close button */}
        </button>
        <p style={styles.filterLabel}>Proficiency Filter</p>
        
        <div style={styles.checkboxList}>
          {uniqueProficiencies.map((proficiency) => (
            <label key={proficiency}>
              <input
                type="checkbox"
                checked={selectedProficiencies[proficiency] !== undefined}
                onChange={() => handleProficiencyChange(proficiency)}
                style={styles.checkbox}
              />
              {proficiency}
            </label>
          ))}
        </div>
      </div>

      <div style={styles.prospects}>
        {sortedProspects.map((prospect) => (
          <div
            style={styles.prospectCard}
            key={prospect.id}
            onClick={() => handleCardClick(prospect.id)} // Handle card click
          >
            <div style={styles.prospectImageContainer}>
              <img
                src={prospect.profilePicture}
                alt={prospect.name}
                style={styles.prospectImage}
              />
              <div
                style={styles.badge}
                onMouseEnter={(e) => {
                  e.currentTarget.querySelector('.badge-tooltip').style.visibility = 'visible';
                  e.currentTarget.querySelector('.badge-tooltip').style.opacity = '1';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.querySelector('.badge-tooltip').style.visibility = 'hidden';
                  e.currentTarget.querySelector('.badge-tooltip').style.opacity = '0';
                }}
              >
                {prospect.userCreatedEventsCount}+
                <span className="badge-tooltip" style={styles.badgeTooltip}>
                  Events Organised
                </span>
              </div>
            </div>
            <h3 style={styles.prospectName}>{prospect.name}</h3>
            <p style={styles.proficiencyHeading}>Proficiency:</p>
            <p style={styles.prospectInfo}>{prospect.interests.join(', ')}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Organiser;
