import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk for verifying OTP
export const verifyOTP = createAsyncThunk(
    'auth/verifyOTP',
    async ({ otp, loginType, inputValue}) => {
        try {
            console.log("sendotp "+ loginType+inputValue)
            let response;

            switch (loginType) {
                case 'email':
                console.log("email input "+inputValue)
                response = await axios.post('https://api.sociverse.in/auth/verify-otp', {
                    email: inputValue,
                    otp: otp
                });
                break;

                case 'phoneNumber':
                response = await axios.post('https://api.sociverse.in/auth/verify-otp', {
                    "mobileNumber": inputValue,
                    "countryCode" : "91",
                    otp: otp
                });
                break;

                default:
                throw new Error('Invalid input type: must be "email" or "number"');
            }

            return response.data;
            ;
        } catch (error) {
            throw error;
        }
    }
);

const initialState = {
    loading: false,
    verified: false,
    error: null,
};

const VerifyotpSlice = createSlice({
    name: 'otp',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
           
            .addCase(verifyOTP.pending, (state) => {
                state.loading = true;
                state.verified = false;
                state.error = null;
            })
            .addCase(verifyOTP.fulfilled, (state) => {
                state.loading = false;
                state.verified = true;
                state.error = null;
            })
            .addCase(verifyOTP.rejected, (state, action) => {
                state.loading = false;
                state.verified = false;
                state.error = action.error.message || 'Failed to verify OTP';
            });
    },
});

export default VerifyotpSlice.reducer;
