import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { fetchAddressDetails } from "../redux/resourcesSlice/EventAdressSlice";
import { GoogleMap, useLoadScript, Marker, Autocomplete } from '@react-google-maps/api';
import apiKey from '../assets/apikey';

const containerStyle = {
    width: '100%',
    height: '400px',
};

function MapComponent({ onLocationSelect }) {
    const dispatch = useDispatch();
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: apiKey,
        libraries: ['places']
    });

    const [markerPosition, setMarkerPosition] = useState(null);
    const autocompleteRef = useRef(null);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    const initialPosition = { lat: latitude, lng: longitude };
                    setMarkerPosition(initialPosition);
                    onLocationSelect(initialPosition);
                    dispatch(fetchAddressDetails({ lat: initialPosition.lat, lng: initialPosition.lng }));
                },
                (error) => {
                    console.error("Error getting geolocation:", error);
                }
            );
        } else {
            console.error("Geolocation is not supported by this browser.");
        }
    }, []);


    const onMapClick = useCallback((event) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        const newPosition = { lat, lng };
        setMarkerPosition(newPosition);
        onLocationSelect(newPosition);
        dispatch(fetchAddressDetails({ lat, lng }));
    }, [onLocationSelect, dispatch]);

    const onPlaceChanged = () => {
        const place = autocompleteRef.current.getPlace();
        if (place.geometry) {
            const newPosition = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
            };
            const {lat,lng}=newPosition;
            setMarkerPosition(newPosition);
            onLocationSelect(newPosition);
            dispatch(fetchAddressDetails({lat , lng }))
        }
    };

    if (loadError) {
        return <div>Error loading maps</div>;
    }

    if (!isLoaded) {
        return <div>Loading map...</div>;
    }

    return (
        <div className='relative'>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={markerPosition || { lat: 0, lng: 0 }}
                zoom={10}
                onClick={onMapClick}
            >
                {markerPosition && (
                    <Marker
                        position={markerPosition}
                    />
                )}
            </GoogleMap>
            <div className="absolute top-4 left-1/2 transform -translate-x-1/2 z-10">
                <Autocomplete
                    onLoad={(ref) => (autocompleteRef.current = ref)}
                    onPlaceChanged={onPlaceChanged}
                >
                    <input
                        type="text"
                        placeholder='Search for place'
                        className='p-2 shadow-xl bg-white rounded-lg'
                    />
                </Autocomplete>
            </div>
        </div>
    );
}

export default MapComponent;
